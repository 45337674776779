/* eslint-disable guard-for-in */

/*
  定義各種常數

  在大螢幕端的 code 部分，player 是指連線進來的 N 個用戶
  在客戶端的 code 部分，user 通常指自己
*/

export const M = {
  SETUP_CONNECTION: '',
  SET_SOCKET_ID: '',
  SET_ROOM_ID: '',

  SET_ID: '',
  SET_NAME: '',
  SET_TYPE: '',
  PLAYERS_RESET: '',
  PLAYER_RESET_MBTI: '',
  CHANGE_PLAYER_SID: '',
  SET_PLAYER_INFO: '',
  SET_PLAYER_STATE: '',
  PLAYER_RESET: '',

  SET_STORY_ID: '',
  SET_SCREEN_ID: '',
  SET_QUES_ID: '',
  SET_QUES: '',

  SET_USER_INFO: '',
  SET_USER_MSG: '',
  TO: '',
};
// 自動填入
for (const key in M) {
  M[key] = key;
}

// store action
export const A = {
  initConnection: '',
  initDisplayerEvents: '',
  joinRoom: '',
  emailLogin: '',
  startGame: '',
  playerInfoUpdate: '',
  initUserEvents: '',
};
// 自動填入
for (const key in A) {
  A[key] = key;
}

export const EV = {
  room: {
    leave: '',
    join: '',
    user_leave: '',
    user_join: '',
  },
  user: {
    set_info: '',
    update_info: '',
    set_state: '',
    update_state: '',

    reconnect: '',
    update_sid: '',

    // user 加入房間被拒絕
    join_reject: '',
    disconnect: '',
  },
  game: {
    // 回首頁 (qrcode 那頁)
    back_home: '',
    start_ques: '',
    change_ques: '',
  },
  // 底下這邊是只有大螢幕端會送出的事件
  control: {
    join: '',
    join_reject: '',
    // 回首頁 (qrcode 那頁)
    back_home: '',
    start: '',
    change_ques: '',
  },
  conv: {
    end: '',
  },
};
// 自動填入
for (const groupKey in EV) {
  const g = EV[groupKey];
  if (typeof g === 'object') {
    for (const key in g) {
      // console.log(`${groupKey}:${key}`);
      g[key] = `${groupKey}:${key}`;
    }
  } else {
    M[groupKey] = groupKey;
  }
}

export function ns(namespace, func) {
  return `${namespace}/${func}`;
}
