import Vue from 'vue';
import VueRouter from 'vue-router';
import { trimEnd } from 'lodash';
import * as layouts from '../layouts';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/kongkoo/new-room',
    name: '/kongkoo/new-room',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/new-room.vue'),
  },
  {
    path: '/kongkoo/displayer',
    name: 'kongkoo/displayer',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/displayer.vue'),
  },
  {
    path: '/kongkoo/swiperPage',
    name: 'kongkoo/swiperPage',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/swiperPage.vue'),
  },
  {
    path: '/kongkoo/controler',
    name: 'kongkoo/controler',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/controler.vue'),
  },
  {
    path: '/kongkoo/dev',
    name: 'kongkoo/dev',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/svg-dev.vue'),
  },
  {
    path: '/kongkoo/user/dev-preview',
    name: 'kongkoo/user/dev-preview',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/user/dev-preview.vue'),
  },
  {
    path: '/kongkoo/user/signIn',
    name: 'kongkoo/user/signIn',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/user/signIn.vue'),
  },
  {
    path: '/kongkoo/user/prepare',
    name: 'kongkoo/user/prepare',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/user/prepare.vue'),
  },
  {
    path: '/kongkoo/user/play',
    name: 'kongkoo/user/play',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/user/play.vue'),
  },
  {
    path: '/kongkoo/user/panel',
    name: 'kongkoo/user/panel',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/user/panel.vue'),
  },
  {
    path: '/kongkoo/user/result',
    name: 'kongkoo/user/result',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/user/result.vue'),
  },
  {
    path: '/kongkoo/user/zoom',
    name: 'kongkoo/user/zoom',
    component: () => import(/* webpackChunkName: "kongkoo" */ '../views/kongkoo/user/zoom.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    let nextLayout = 'empty';
    const toPath = to.matched[0]?.path || to.path;
    // 換頁前先手動尋找對應 route
    for (const route of routes) {
      // 目前是直接透過 path 和 matched 比較，
      // 可支援的路由類型包含
      // 任何一般的路由(範例：/index)
      // 帶參數的路由 /abc/:id , /abc/:id1/:id2
      const rPath = trimEnd(route.path, '/');
      // console.log(`${rPath} => ${toPath}`);
      if (rPath === toPath) {
        let component;
        if (route.component) {
          if (typeof route.component === 'function') {
            // eslint-disable-next-line no-await-in-loop
            const module = await route.component();
            component = module.default;
          } else {
            component = route.component;
          }
          // 尋找 {component}.layout 的屬性，如果找不到就設為 empty
          // 找到後同步至 store ，修改當前頁面的 layout 元件
          // console.log('component', component);
          if (component.layout && component.layout in layouts) {
            nextLayout = component.layout;
          }
          console.log('component.layout', component.layout);
        }
        break;
      }
    }
    store.commit('SET_LAYOUT', nextLayout);
    next();
  } catch (e) {
    console.error('error in router.beforeEach');
    console.error(e);
    store.commit('SET_LAYOUT', 'empty');
    next();
  }
});

export default router;
