import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/img-cors';
import './plugins/html2canvas';
import './assets/styles/base.css';
import CustomDialog from './components/CustomDialog.vue';

Vue.component('CustomDialog', CustomDialog);
Vue.config.productionTip = false;

const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
