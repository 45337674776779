import Vue from 'vue';
import Vuex from 'vuex';

import kongkoo from './kongkooV2';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: '',
    layout: 'empty',
    screen: { width: 0, height: 0 },
  },
  mutations: {
    SET_TITLE(state, title) {
      state.title = title;
    },
    SET_LAYOUT(state, layout) {
      state.layout = layout;
    },
    SET_SCREEN(state, screen) {
      state.screen = screen;
    },
  },
  actions: {
    /**
     * 更新畫面的大小
     * @param {*} ctx
     * @param {*} data
     */
    updateScreen({ commit }, data) {
      // eslint-disable-next-line no-param-reassign
      data = data || {};
      const height = data.height || window.innerHeight;
      const width = data.width || window.innerWidth;
      commit('SET_SCREEN', { width, height });
    },
  },
  modules: {
    kongkoo,
  },
});
