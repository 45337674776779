<template>
  <div :is="layout" id="app">
    <router-view />
  </div>
</template>
<script>
import resizeMixin from './mixins/resize';
import * as layouts from './layouts';

export default {
  mixins: [resizeMixin],
  components: {
    ...layouts,
  },
  computed: {
    layout() {
      console.log('app.layout:', this.$store.state.layout);
      return this.$store.state.layout;
    },
  },
  created() {
    console.log(this.$route);
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
#app {
  .ns-layout {
    font-style: normal;
    font-family: 'Noto Sans TC S', 'Noto Sans TC', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  .v-application {
    font-style: normal;
    font-family: 'Noto Sans TC S', 'Noto Sans TC', sans-serif;
    -webkit-font-smoothing: antialiased;
  }
}
</style>
