<template>
  <transition name="fade">
    <div class="cover" v-if="show">
      <transition name="pop" appear>
        <section v-if="showContent" class="dialog-content">
          <p class="msg" v-html="msg"></p>
          <img class="yellow-guys-img" src="@/assets/yellow-guys.png" alt="" />
          <button class="btn" @click="closeEv">確認</button>
        </section>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CustomDialog',
  props: {
    msg: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { showContent: false };
  },
  watch: {
    show: {
      immediate: true,
      handler(bool) {
        if (bool) {
          setTimeout(() => {
            this.showContent = true;
          }, 300);
        }
      },
    },
  },
  methods: {
    closeEv() {
      this.showContent = false;
      setTimeout(() => {
        this.$emit('update:show', false);
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
*{
  position: relative;
}
.cover {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 328px;
  max-width: 76.635%;
  padding: 27px 31px 25px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.5));
  background: #ffffff;
  border-radius: 10px;
  .msg {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #6a6a6a;
  }
  .yellow-guys-img {
    top: 36px;
  }
  .btn {
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 192px;
    height: 53px;
    background: #f99500;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: 0.2em;
    color: #ffffff;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 28px;
      left: 0;
      top: 0;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(-180deg) translateY(100%);
    }
  }
}
</style>
