/* eslint-disable space-before-blocks */
/* eslint-disable quote-props */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
/**
 * @typedef {import('vuex').Module} VuexModule
 */
import { io } from 'socket.io-client';
import * as kgApi from '@/lib/kongkooV2-api';
import { A, M, EV } from '@/lib/kongkooV2-def';
/**
 * @typedef {import('socket.io-client').Socket} Socket
 */
const mbtiKeys = ['外向', '直覺', '理性', '熟思'];

const bgColorMap = {
  0: '#E09797',
  1: '#F4DB82',
  2: '#A8CB93',
  3: '#A9CEDB',
};

function zeroMbti() {
  return {
    外向: 0,
    直覺: 0,
    理性: 0,
    熟思: 0,
  };
}
function emptyPlayer(num, opts = {}) {
  return {
    sNum: num,
    sid: opts.sid || '',
    nickname: '',
    email: '',
    join: false, // join = sid && email
    state: 'init',
    view: 'stt', // mbti , stt
    stt: '',
    backgroundColor: bgColorMap[num],
    mbti: null,
    // mbti: {
    //   convId: '',
    //   category: '',
    //   type: '',
    //   quesId: '',
    //   summary: zeroMbti(),
    // },
    panelData: null,
  };
}

function hookSocketEvent(socket) {
  let func = socket.on;
  socket.on = (ev, cb) => {
    let newCB = function () {
      // eslint-disable-next-line prefer-rest-params
      return cb.apply(socket, arguments);
    };
    return func.apply(socket, [ev, newCB]);
  };
}

/**
 * @type {VuexModule}
 */
export const m = {
  namespaced: true,
  state: () => ({
    dev: false,
    // {socket io} instance
    /** @type {Socket} */
    socket: null,
    socketId: '',
    screenId: '',
    storyId: '',
    roomId: '',
    name: '',
    type: 'user',
    view: 'prepare',
    key: '',
    // "user" 用
    user: {
      nickname: '',
      email: '',
      error: null,
      message: '',
    },
    ques: null,
    // "displayer" 用
    players: [emptyPlayer(0), emptyPlayer(1), emptyPlayer(2), emptyPlayer(3)],
  }),
  getters: {
    getPlayerSidList(state) {
      return state.players.filter((player) => player.join).map((player) => player.sid);
    },
    getPlayer(state) {
      return (sid) => state.players.find((player) => player.sid === sid);
    },
    getPlayerNum(state) {
      return (sid) => state.players.findIndex((player) => player.sid === sid);
    },
    getEmptyPlayerField(state) {
      // console.log('getEmptyPlayerField', state.players);
      for (let i = 0; i < state.players.length; i += 1) {
        let player = state.players[i];
        if (!player.join) {
          return i;
        }
      }
      return -1;
    },
  },
  mutations: {
    [M.SETUP_CONNECTION](state, socket) {
      state.socket = socket;
    },
    [M.SET_ID](state, id) {
      state.id = id;
    },
    [M.SET_SOCKET_ID](state, socketId) {
      state.socketId = socketId;
    },
    [M.SET_ROOM_ID](state, roomId) {
      state.roomId = roomId;
    },
    [M.SET_NAME](state, name) {
      state.name = name;
    },
    /** 客戶端類型 */
    [M.SET_TYPE](state, type) {
      state.type = type;
    },
    [M.SET_PLAYER_INFO](state, {
      num, sid, nickname, email,
    }) {
      let player = state.players[num];
      player.sid = sid;
      player.email = email;
      player.join = !!(sid && email);
      player.nickname = nickname;
      player.state = 'joined';
    },
    /**
     * 修改第 num 個 player 的 sid
     */
    [M.CHANGE_PLAYER_SID](state, { num, sid }) {
      let player = state.players[num];
      if (player) {
        player.sid = sid;
      }
    },
    [M.SET_PLAYER_STATE](state, data) {
      const { players } = state;
      let sid = data.sid;
      delete data.sid;
      const player = players.find((p) => p.sid === sid);
      if (player) {
        Object.assign(player, data);
      }
    },
    // [M.PLAYER_JOIN](state, { num, id }) {
    //   if (state.players[num]) {
    //     state.players[num].join = true;
    //     state.players[num].id = id;
    //   }
    // },
    [M.PLAYER_RESET](state, num) {
      if (state.players[num]) {
        Vue.set(state.players, num, emptyPlayer(num));
      }
    },
    // [M.PLAYER_RESET_MBTI](state, num) {
    //   if (state.players[num]) {
    //     state.players[num].mbti = zeroMbti();
    //   }
    // },
    [M.SET_USER_INFO](state, { nickname, email }) {
      state.user.email = email;
      state.user.nickname = nickname;
    },
    [M.SET_QUES_ID](state, quesId) {
      state.quesId = quesId;
    },
    [M.SET_QUES](state, ques) {
      state.ques = ques;
    },
    [M.SET_USER_MSG](state, msg) {
      state.user.message = msg;
    },
    // [M.SET_STORY_ID](state, id) {
    //   state.storyId = id;
    // },
    [M.SET_SCREEN_ID](state, id) {
      state.screenId = id;
    },
    // 切換頁面
    [M.TO](state, to) {
      state.view = to;
    },
  },
  actions: {
    [A.initConnection]({ state, commit, dispatch }, opts = {}) {
      return new Promise((resolve, reject) => {
        const { roomId } = opts;
        if (!state.socket) {
          let first = true;
          let socket = io(process.env.VUE_APP_WS_HOST || 'https://ws.voiss.cc');
          let noUser = opts.type !== 'user';
          let type = opts.type || 'user';
          // 有下面這行的話，執行的時候收到事件都會 log 到 console
          hookSocketEvent(socket);
          // let socket = io('https://ws.voiss.cc');
          // let socket = io('http://localhost:3002');
          // let socket = io('http://localhost:2882');

          commit(M.SETUP_CONNECTION, socket);
          commit(M.SET_TYPE, type);
          if (opts.quesId) {
            commit(M.SET_QUES_ID, opts.quesId);
          }
          // if (type === 'screen' || type === 'admin') {
          //   dispatch(A.initAdminEvents, socket);
          // } else {
          //   dispatch(A.initUserEvents, socket);
          // }
          socket.on(EV.game.change_ques, (ques) => {
            // console.log('on', EV.game.change_ques);
            commit(M.SET_QUES_ID, ques.id);
            commit(M.SET_QUES, ques);
          });
          if (type === 'user') {
            socket.on(EV.user.join_reject, ({ msg }) => {
              console.log(EV.user.join_reject, msg);
              window.alert(msg);
            });
          }
          socket.on('connect', async () => {
            if (roomId) {
              // 每次連線或重新連線的第一件事情都是要加入對應的房間
              await dispatch(A.joinRoom, roomId);
            }
            if (first) {
              commit(M.SET_SOCKET_ID, socket.id);
              resolve(socket);
            } else {
              const { user, socketId } = state;
              // user 自動重連的時候要更新大螢幕對應的 sid
              if (!noUser) {
                socket.emit(EV.user.reconnect, {
                  oSid: socketId,
                  roomId,
                });
              }
              // 避免重新連接後，大螢幕上面的暱稱和信箱沒有同步
              if (user.nickname && user.email) {
                await dispatch(A.emailLogin, {
                  oSid: socketId,
                  reconnect: true,
                  nickname: user.nickname,
                  email: user.email,
                });
              }
              console.log('reconnect');
              console.log('new sid =', socket.id);
              commit(M.SET_SOCKET_ID, socket.id);
            }
            first = false;
          });
        } else {
          resolve(state.socket);
        }
      });
    },
    async [A.joinRoom]({ state, commit }, roomId) {
      const { socket, type } = state;
      if (type === 'user') {
        socket.emit(EV.room.join, { roomId });
      } else {
        socket.emit(EV.control.join, { roomId });
      }
      commit(M.SET_ROOM_ID, roomId);
    },
    // 用戶端呼叫的
    async [A.emailLogin]({ state, commit }, data = {}) {
      const { socket } = state;
      const { nickname, email } = data;
      if (!socket) {
        throw new Error('no connect');
      }
      const params = {
        sid: socket.id,
        nickname,
        email,
      };
      await kgApi.emailLogin(params);
      const event = {
        oSid: data.oSid,
        reconnect: !!data.reconnect,
        ...params,
      };
      socket.emit(EV.user.set_info, event);
      commit(M.SET_USER_INFO, params);
    },
    [A.initUserEvents]({ state, commit, dispatch }, socket) {},
    [A.initDisplayerEvents]({
      state, commit, dispatch, getters,
    }) {
      const socket = state.socket;
      console.log('initDisplayerEvents');
      socket.on(EV.user.update_sid, async ({ oSid, sid: newSid, roomId }) => {
        let num = getters.getPlayerNum(oSid);
        if (num >= 0) {
          commit(M.CHANGE_PLAYER_SID, { num, sid: newSid });
        } else {
          console.log('no update sid');
        }
      });
      socket.on(EV.user.update_info, async ({
        sid, email, nickname, reconnect, oSid,
      }) => {
        console.log(EV.user.update_info);
        // 先檢查 user 是否已經連線
        let num = getters.getPlayerNum(sid);
        if (num >= 0) {
          commit(M.SET_PLAYER_INFO, { sid, email, nickname });
          return;
        }
        if (reconnect) {
          num = getters.getPlayerNum(oSid);
          if (num >= 0) {
            commit(M.SET_PLAYER_INFO, { sid: oSid, email, nickname });
            return;
          }
        }
        if (state.view === 'prepare') {
          if (getters.getEmptyPlayerField !== -1) {
            // 加入新玩家
            await dispatch(A.playerInfoUpdate, {
              sid,
              email,
              nickname,
            });
          } else {
            socket.emit(EV.control.join_reject, { sid, msg: '人數已滿' });
          }
        } else {
          socket.emit(EV.control.join_reject, { sid, msg: '已經開始，無法加入' });
        }
        // 每個用戶進來之後都要更新大家的"題目資料"
        socket.emit(EV.control.change_ques, { roomId: state.roomId, quesId: state.quesId });
      });
      socket.on(EV.user.update_state, async (data) => {
        commit(M.SET_PLAYER_STATE, data);
      });
      socket.on(EV.user.disconnect, async (sid) => {
        // 只有在準備畫面的時候有人退出要清空資料
        // 其他時候要可以等用戶回來
        if (state.view === 'prepare') {
          dispatch(A.playerInfoUpdate, {
            sid,
            email: '',
            nickname: '',
          });
        }
      });
    },
    // 螢幕端按下開始遊戲
    [A.startGame]({ state, commit }) {
      const { socket, roomId, quesId } = state;
      console.log('action', A.startGame);
      socket.emit(EV.control.change_ques, { roomId, quesId });
      socket.emit(EV.control.start);
      commit(M.TO, 'game');
    },
    // 螢幕端更新畫面資訊用
    [A.playerInfoUpdate]({ state, commit, getters }, data) {
      const { sid, email, nickname } = data;
      console.log('playerInfoUpdate');
      let num = getters.getPlayerNum(sid);
      console.log('playerInfoUpdate', data);
      if (num === -1) {
        num = getters.getEmptyPlayerField;
      }
      if (num === -1) {
        // 滿了
        return;
      }
      const params = {
        num,
        sid,
        nickname,
        email,
      };
      commit(M.SET_PLAYER_INFO, params);
    },
    [A.playerInfoMove]({ state, commit }, data) {
      // 從舊的連線轉移到新的連線
      // const { oSid, sid }
    },
  },
};

export default m;
