/* eslint-disable import/prefer-default-export */
import Axios from 'axios';

const host = process.env.VUE_APP_API_HOST || 'https://api.voiss.cc';

export const axios = Axios.create({
  baseURL: host,
  withCredentials: true,
});

export async function emailLogin({ sid, nickname, email }) {
  const res = await axios.post('/kongkoo/v2/email-login', {
    sid,
    nickname,
    email,
  });
  return res.data;
}

export async function ques({
  sid, quesId, query, nickname, email,
}) {
  const res = await axios.post('/kongkoo/v2/ques', {
    sid,
    quesId,
    query,
    nickname,
    email,
  });
  return res.data;
}

export async function stt(audio) {
  if (!audio) {
    throw new Error('缺少必要參數');
  }
  const fd = new FormData();
  fd.append('audio', audio);
  const res = await axios.post('/kongkoo/stt', fd);
  return res.data;
}

export async function getPostcard(convId) {
  const res = await axios.get('/kongkoo/v2/ques-postcard', {
    params: {
      convId,
    },
  });
  return res.data;
}
