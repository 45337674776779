/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
import Vue from 'vue';

function onLoadSuccess(e) {
  /** @type {HTMLElement} */
  // const el = e.target;
}
function onLoadFailed(e) {
  /** @type {HTMLElement} */
  const el = e.target;
  console.log('failed', el.src);
  setTimeout(() => {
    if (el.crossOrigin) {
      console.log('retry', el.src);
      el.removeAttribute('crossorigin');
    }
  }, 300);
}

Vue.directive('cors', {
  bind(el) {
    el.crossOrigin = 'anonymous';
    el.addEventListener('load', onLoadSuccess);
    el.addEventListener('error', onLoadFailed);
  },
  // When the bound element is inserted into the DOM...
  update(el) {
    el.crossOrigin = 'anonymous';
    // Focus the element
    const s = el.src;
    el.src = '';
    el.src = s;
  },
  unbind(el) {
    el.removeEventListener('load', onLoadSuccess);
    el.removeEventListener('error', onLoadFailed);
  },
});
