import empty from './empty.vue';
import app from './app.vue';

/*
  注意事項

  所有 layout 都要在這個檔案 export 才可用

  layout 中不可以放 router-view ，要用 slot 取代
*/

export default empty;
export {
  empty,
  app,
};
